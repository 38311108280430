/** @jsxImportSource @emotion/react */

import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useAtom } from "jotai";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

import { LoginContext } from "../../context/login-context";

// import AuthContext from "../../context/auth-context";
import VOCClient from "../../VOCClient";

import authCss from "./auth.module.scss";

import { isLoggedInAtom } from "../../components/atoms";
import logo from "../../components/logo/valley-logo.png";
import FormikTextField from "../../components/form/formik-text-field/formik-text-field";
import { CustomButton } from "../../components/custom-button/custom-button";

import ForgotPasswordForm from "./forgotpasswordform";

import { css } from "./css";

import { fontbox, paddingX, paddingY, theme } from "../../emotion/utils";

const Login = () => {
	const [hasError, setHasError] = useState(false);
	const navigate = useNavigate();
	const [, setIsLoggedIn] = useAtom(isLoggedInAtom);
	const [isResettingPassword, setIsResettingPassword] = useState(false);
	const { saveUserData } = useContext(LoginContext);

	function handleErrorBtn() {
		setTimeout(() => setHasError(false), 500);
	}

	const validationSchema = Yup.object({
		email: Yup.string().label("Email").required("Email is required"),
		password: Yup.string().label("Password").required("Password is required"),
	});

	const icon = (
		<svg width="100" height="100" viewBox="0 0 100 100">
			<circle cx="50" cy="50" r="50" fill="#D52C51" />
			<path
				d="M70 35.7143L64.2857 30L50 44.2857L35.7143 30L30 35.7143L44.2857 50L30 64.2857L35.7143 70L50 55.7143L64.2857 70L70 64.2857L55.7143 50L70 35.7143Z"
				fill="white"
			/>
		</svg>
	);

	const errorBtn = (
		<CustomButton
			label="Try Again"
			align="center"
			onClick={handleErrorBtn}
		/>
	);

	const forgotPasswordArea = <ForgotPasswordForm setOpen={setIsResettingPassword} />;

	const errorBox = (
		<AnimatePresence>
			<motion.div
				key="errorOverlay"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0, transition: { duration: 0.5 } }}
				className={authCss.errorOverlay}
				style={{zIndex:"2"}}
			>
				{icon}

				<div className={authCss.title}>Could not login</div>
				<div className={authCss.subtitle}>
					Wrong Username or Password
				</div>

				{errorBtn}
			</motion.div>
		</AnimatePresence>
	);

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const username = values["email"];
			const password = values["password"];
			const ret = await VOCClient.login(username, password);
			if (!ret.ok) {
				setHasError(true);
				setIsLoggedIn(false);
			}

			const response = ret.body;
			setIsLoggedIn(true);
			saveUserData(response);
			navigate("/");
		},
	});

	return (
    <main css={css.main}>
      <FormikProvider value={formik}>
        <Form css={css.form}>
          {hasError && errorBox}

          <img src={logo} alt="logo" />

          {isResettingPassword && forgotPasswordArea}

          <div css={css.fieldset(isResettingPassword)}>
            <div css={css.fieldset().title}>Login</div>

            <div css={css.fieldset().fields}>
              <FormikTextField
                label="Email"
                labelShrink={true}
                name="email"
                type="text"
              />

              <FormikTextField
                label="Password"
                labelShrink={true}
                name="password"
                type="password"
              />

              <CustomButton
                type="submit"
                label="Login"
                isFullWidth={true}
                style={{ marginTop: 20 }}
              />

              <CustomButton
                label="Forgot Password?"
                customType="button"
                color="primary"
                type="button"
                fs={10}
                align="center"
                onClick={() => {
                  console.log(
                    "setting forgot password.."
                  );
                  setIsResettingPassword(true);
                }}
                variant="outlined"
                style={{
                  ...paddingX(0),
                  ...paddingY(0),
                  border: "none",
                  flex: "unset",
                  height: "auto",
                  width: "auto",
                }}
                labelStyle={{
                  ...fontbox(
                    theme.fonts.inter,
                    12,
                    600,
                    20
                  ),
                }}
              />
            </div>
          </div>
        </Form>
      </FormikProvider>
    </main>
	);
};

export default Login;
