import {jwtDecode} from "jwt-decode";
import Cookies from 'universal-cookie';

export default class VOCClient {
  static isLoggedIn() {
    const token = VOCClient.getToken();
    const isloggedin = !!token;
    
    return isloggedin;
  }

  static getTokenDecoded(){
    const token = VOCClient.getToken();
    
    if(token){
      return jwtDecode(token);
    }
  }

  static customerName(){
    // return VOCClient.getTokenDecoded() && VOCClient.getTokenDecoded().customerName ? VOCClient.getTokenDecoded().customerName : "Unknown"
    return 'Clover Farms';
  }

  static customerId(){
    return VOCClient.getTokenDecoded() && VOCClient.getTokenDecoded().customerId ? VOCClient.getTokenDecoded().customerId : -1
  }

  static async standardFetch(url) {
    const headers = VOCClient.getHeaders();
    const response = await fetch(url, { headers });

    var body;
    if (response.ok) {
      body = await response.json(); 
    }
    
    return { response, body };
  }
  
  static async saveCamera(camera) {
    let body = false;

    const cameraPayload = JSON.stringify(camera);

    const response = await fetch('/api/camera/update', {
      method: 'POST',
      body: cameraPayload,
      headers:{
        'Content-Type': 'application/json'
      }
    });

    const jsonResponse = await response.json();

    if (jsonResponse) {
      body = jsonResponse;
    }

    return body;
  }

  static async fetchVideos() {
    console.log('trying to load videos..');
    const response = await fetch('/api/image/fetch-new-videos',
      {
        method: 'GET',
        headers:{
          'Content-Type': 'application/json'
        }
      }
    );  

    console.log('response is..', response);

    const body = await response.json();

    return { response, body };
  }

  static async fetchImages() {
    console.log('trying to load images..');
    const response = await fetch('/api/image/fetch-new-images',
      {
        method: 'GET',
        headers:{
          'Content-Type': 'application/json'
        }
      }
    );

    console.log('response is..', response);

    const body = await response.json();

    return { response, body };
  }

  static async resetPassword(data) {
    console.log('need to reset password..', data);

    const response = await fetch('/api/users/reset-password', {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{
        'Content-Type': 'application/json'
      }
    });

    const body = await response.json();

    return { response, body };  
  }

  static async forgotPassword(email) {
    const requestBody = { email };
    const response = await fetch('/api/users/forgot-password', {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers:{
        'Content-Type': 'application/json'
      }
    });

    const body = await response.json();

    return { response, body };  
  }

  static async login(email, password) {
    
    const requestBody = { email, password };
    const response = await fetch('/api/users/login',
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers:{
        'Content-Type': 'application/json'
      }
    });

    const body = await response.json();

    return { response, body };  
  }

  static async refresh(token) {
    const response = await fetch('/api/users/refresh',
    {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    const body = await response.json();

    return { response, body };  
  }

  static getHeaders() {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    if (VOCClient.isLoggedIn()) {
        headers['Authorization'] = 'Bearer ' + VOCClient.getToken()
    } else {
        // console.log("Not loogged in");
    }

    return headers;
  }

  static getToken() {
    const cookies = new Cookies();
    return cookies.get("voc_token");
  }

//   static async getS3UploadUrl(where) {
//     return VOCClient.standardFetch(`/api/v1/doc_upload/sign-s3-pdf?directory=${where}`);
//   }


  static async getCustomerFarmsAndFields(start, end) {
    let url = '/api/customer';

    const query = [];
    if (start) {
      query.push(`start=${start}`);
    }
    if (end) {
      query.push(`end=${end}`);
    }

    if (query.length) {
      url += `?${query.join('&')}`;
    }

    const ret = await VOCClient.standardFetch(url);
    return ret.body;
  }

  static async getAllCameras() {
    const ret = await VOCClient.standardFetch(`/api/camera/all`);
    return ret.body;
  }

  static getImageFromServer(image_id) {
    return `/api/image/view-image/${image_id}`;
  }

  static async getImageTimes(field) {
    return await VOCClient.standardFetch(`/api/camera/image_times/${field}`);
  }

  static async getNewImageFromServer(camera_id) {
    return await VOCClient.standardFetch(`/api/camera/new-image/${camera_id}`);
  }

  static async getCameras(field) {
    return await VOCClient.standardFetch(`/api/camera/all_for_field/${field}`);
  }

//   static async getProjects() {
//     let res = await VOCClient.standardFetch('/api/v1/projects');
//     // console.log(res)
//     return res;
//   }




//   static async getTransmissionAssetSummary() {
//     return await VOCClient.standardFetch(`/api/v1/dashboard/summary`);
//   }

//   static async getTransmissionAssetLine(line, orderBy, order, offset, limit) {
//     return await VOCClient.standardFetch(`/api/v1/dashboard/structures?line_id=${line}&orderBy=${orderBy}&order=${order}&offset=${offset}&limit=${limit}`);
//   }


//   static async getUnassignedImages(projectId) {
//     return await VOCClient.standardFetch(`/api/v1/inspections/unassignedImages/${projectId}`);
//   }

//   static async getAssignedImages(projectId, groupBy) {
//     return await VOCClient.standardFetch(`/api/v1/inspections/assignedImages/${projectId}?groupBy=${groupBy}`);
//   }

//   static async getStructuresWithImagesByDate(projectId, groupBy, date) {
//     return await VOCClient.standardFetch(`/api/v1/inspections/structuresWithImagesByDate/${projectId}?groupBy=${groupBy}&date=${date}`);
//   }

//   static async getImagesForStructureByDate(projectId, groupBy, date, structureId) {
//     return await VOCClient.standardFetch(`/api/v1/inspections/imagesForStructureByDate/${projectId}?groupBy=${groupBy}&date=${date}&structureId=${structureId}`);
//   }



}
