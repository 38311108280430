import React, { useState, useMemo, useEffect, forwardRef } from 'react';

import imgLoading from "../../assets/images/loadingImg.png";
import { square } from '../../emotion/utils';

function ImgWithLoader(props, ref) {
  const [show, setShow] = useState(false);

  const { src, text, isLoading, onLoad } = props;

  const imgEl = useMemo(() => {
    if (isLoading) {
      return null;
    }

    const isBase64 = /data:image\/png;base64,/.test(src);

    const timestamp = new Date().getTime();

    const url = isBase64 ? src : `${src}?time=${timestamp}`;

    const imgProps = {...props};
    delete imgProps.isLoading;

    return (
      <img
        ref={ref}
        key={src}
        {...imgProps}
        src={url}
        onLoad={() => {
          setShow(true);
          if (onLoad) onLoad(true);
        }}
        alt=""
      />
    );
  }, [ref, src, props, isLoading, onLoad]);

  useEffect(() => {
    setShow(false);
    if (onLoad) onLoad(false);
  }, [src, onLoad])

  let loaderEl = null;
  if (isLoading || !show) {
    loaderEl = (
      <img
        src={imgLoading}
        alt="loading..."
        style={{ position: 'absolute', width: "50%" }}
      />
    );
  }

  return (
    <>
      {loaderEl}
      {src && imgEl}
    </>
  );
}

export default forwardRef(ImgWithLoader);
