/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import defaultImage from '../../assets/images/loader.gif';

import { css } from './css';

const VideoPlayer = ({
    url, 
    status,
    setStatus,
    style,
    liveImage
 }) => {
  const hasLiveImage = liveImage?.img ? true : false;
  console.log('hasLiveImage', hasLiveImage);

  const [loading, setLoading] = useState(true);

  const ref = useRef();
  const refDiv = useRef();

  useEffect(() => {
    if (!url) {
      return;
    }

    setLoading(true);
    setStatus("Initializing live stream...");

    const player = new window.JSMpeg.Player(url, {
      canvas: ref.current,
      autoplay: true,
      onVideoDecode: () => {
        setLoading(false)
        setStatus("Camera found, streaming...")
      }
    });

    return () => {
      player.destroy();
    }
  }, [url]);

  const getLiveImage = !hasLiveImage ? (
    <img
      src={defaultImage}
      alt="loading"
      css={css.loader.imgDefault}
    />
  ) : (
    <img
      src={liveImage}
      // src={'https://valmontvoc.s3.us-west-2.amazonaws.com/f0695313-0c20-495c-bfb0-fdd115ff4afc/15c8d444-8747-406c-9556-53911f063545/111b6cc7-a0c0-4b0d-9e3f-d50b5c4c1f26/5565ebfa-4a81-4ad2-b573-a507e3ac2b41 - Mon, 05 Aug 2024 21:03:04 GMT.jpg'}
      alt="loading"
      css={css.loader.imgThumb}
    />
  );

  const loader = (
    <div css={css.loader}>
      {getLiveImage}
    </div>
  );

  return (
    <div css={css.container(false, loading)} style={style}>
      <div ref={refDiv}>
        {loading && loader}
        <canvas ref={ref} width="500" height="500"></canvas>
      </div>      
    </div>
  );
};

VideoPlayer.props = {
  url: PropTypes.string.isRequired
};

export default VideoPlayer;