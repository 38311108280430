/** @jsxImportSource @emotion/react */

import React, { Component } from "react";

import { Toast } from "primereact/toast";

import { borderRadius, colors, flexbox, fullHeight, paddingX, paddingY, theme } from "../../emotion/utils";

import logo from '../../components/logo/valley-logo.png';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.toast = React.createRef();
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);

    this.toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "An unexpected error occurred.",
      life: 5000,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <main css={css.main}>
          <div css={css.errorBlock}>
            <img src={logo} alt="logo" />
            <h1>Oops. Something went wrong.</h1>
            <a href="/">Back</a>
            <Toast ref={this.toast} />
          </div>
        </main>
      );
    }

    return (
      <>
        {this.props.children}
        <Toast ref={this.toast} />
      </>
    );
  }
}

const css = {
  main: {
    ...flexbox("column", "center", "center"),
    ...fullHeight(),
    backgroundColor: theme.colors.bgSecondary,
  },

  errorBlock: {
    ...borderRadius(12),
    ...flexbox("column", "center", "center", 40),
    ...paddingX(60),
    ...paddingY(60),
    backgroundColor: theme.colors.white,
    boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',   

    img: {
      height: 'auto',
      width: 200,
    },
  },
};

export default ErrorBoundary;
