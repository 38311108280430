import { useContext, useState } from 'react';
import { useMutation as reactQueryUseMutation } from '@tanstack/react-query';

import { LoginContext } from '../context/login-context';

export default function useProtectedMutation(props) {
  const { runProtected } = useContext(LoginContext);

  const query = reactQueryUseMutation({
    ...props,
    mutationFn: async (...args) => {
      const data = await runProtected(props.mutationFn, ...args);
      return data;
    },
  });

  return query;
}
