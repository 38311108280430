import {
	borderTopLeftRadius,
	borderTopRightRadius,
	flexbox,
	fullWidth,
	objectContain,
	posAbsolute,
	posRelative,
	square,
	theme,
} from "../../emotion/utils";

const getHeight = (isSelected, loading) => {
  // console.log('getHeight isSelected', isSelected);
  // console.log('getHeight loading', loading);
  // let height = 335;

  // console.log('getHeight height', height);

  const height = !isSelected
    ? 335
    : isSelected && loading
    ? 550
    : "unset";

  return height;
};

export const css = {
	container: (isSelected, loading) => ({
		...fullWidth(),
		// height: isSelected ? "unset" : 335,
    height: getHeight(isSelected, loading),

		"> div": {
			...borderTopLeftRadius(12),
			...borderTopRightRadius(12),
			...flexbox("column", "center", "center", 0),
      ...posRelative(),
      ...square("inherit"),
			backgroundColor: loading ? theme.colors.white : `${theme.colors.textPrimary}25`,
			overflow: "hidden",

			canvas: {
        ...objectContain()
			}
		}
	}),

  loader: {
    ...flexbox("column", "center", "center", 0),
    ...posAbsolute(0, 0, 0, 0),
    ...fullWidth(),
    backgroundColor: theme.colors.white,
    minHeight: 335,

    imgDefault: {
      ...square(`150px!important`)
    },

    imgThumb: {
      height: "auto!important",
      width: "100%!important",
    },
  }
};
