/** @jsxImportSource @emotion/react */

import { borderRadius, flexbox, fullHeight, paddingX, paddingY, theme } from "../../emotion/utils";

import logo from '../../components/logo/valley-logo.png';

export function NotFound() {
  return (
    <main css={css.main}>
      <div css={css.block}>
        <img src={logo} alt="logo" />
        <h1>404 Not Found</h1>
        <a href="/">Back</a>
      </div>
    </main>
  );
}

const css = {
  main: {
    ...flexbox("column", "center", "center"),
    ...fullHeight(),
    backgroundColor: theme.colors.bgSecondary,
  },

  block: {
    ...borderRadius(12),
    ...flexbox("column", "center", "center", 40),
    ...paddingX(60),
    ...paddingY(60),
    backgroundColor: theme.colors.white,
    boxShadow: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',   

    img: {
      height: 'auto',
      width: 200,
    }, 
  },
};

export default NotFound;
