import moment from 'moment';

const FETCH_TIMEOUT = 15 * 1000;

class ImageCache {
  constructor(staleTimeInMins) {
    this.images = {};

    this.staleTimeInMins = staleTimeInMins || 15;
  }

  isStale(key) {
    const image = this.images[key];
    if (!image) {
      return true;
    }

    const diff = new moment().diff(image.timestamp, 'minutes');

    return diff > this.staleTimeInMins;
  }

  getImageBlob(key, url) {
    return new Promise(async (resolve) => {
    	if (!this.isStale(key)) {
        resolve({ img: this.images[key].base64, error: false });
      }

      this.images[key] = {
        timestamp: new moment(),
        base64: null
      };

      try {
        const res = await fetch(url, { signal: AbortSignal.timeout(FETCH_TIMEOUT) });
        const blob = await res.blob();

        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = () => {
          const base64 = reader.result;

          this.images[key] = {
            timestamp: new moment(),
            base64
          };

          resolve({ img: base64, error: false });
        };

        return null;
      } catch (err) {
        if (this.isStale(key)) {
          resolve({ img: null, error: true });
        } else {
          const img = this.images[key].base64;
          resolve({ img, error: !img });
        }
      }
    });
  }
}

export default ImageCache;
