/** @jsxImportSource @emotion/react */

import { useContext, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import VOCClient from "../../VOCClient";

import logo from "../../components/logo/valley-logo.png";
import FormikTextField from "../../components/form/formik-text-field/formik-text-field";
import { CustomButton } from "../../components";

import { css } from "./css";

function ResetPassword(props) {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	let uid = searchParams.get("userId");
	let token = searchParams.get("token");

	const initialValues = {
		confirmPassword: "",
		password: "",
		userId: uid,
		token,
	};

	const toastRef = useRef();
	const [hasError, setHasError] = useState(false);

	const { mutateAsync: onSubmit, isLoading } = useMutation({
		mutationKey: "reset_password",
		mutationFn: async (data) => {
			const response = VOCClient.resetPassword(data);
			return response;
		},
		onSuccess: (data) => {
			alert("Congratulations, your password has been changed!");
			navigate("/login");
		},
		onError: (error) => {
			toastRef.current.show({
				severity: "error",
				summary: "Error",
				detail: error.toString(),
				life: 13000,
			});
			setHasError(true);
		},
	});

	return (
		<main css={css.main}>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validationSchema={Yup.object({
					password: Yup.string().label("Password").min(6).required(),
					confirmPassword: Yup.string()
						.label("Confirm Password")
						.oneOf(
							[Yup.ref("password"), null],
							"Passwords must match"
						),
				})}
			>
				{(formik) => (
					<Form css={css.form}>
						<img src={logo} alt="logo" />

						<div css={css.fieldset()}>
							<div css={css.fieldset().title}>Password Reset</div>

							<div css={css.fieldset().fields}>
								<FormikTextField
									label="Password"
									labelShrink={true}
									name="password"
									type="password"
									// value={formik.values.password}
								/>

								<FormikTextField
									label="Confirm Password"
									labelShrink={true}
									name="confirmPassword"
									type="password"
									// value={formik.values.confirm_password}
								/>

								<CustomButton
									type="submit"
									label="Submit"
									disabled={
										isLoading ||
										Object.values(formik.errors).length ||
										hasError
									}
									isFullWidth
									style={{ marginTop: 20 }}
								/>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</main>
	);
}

export default ResetPassword;
