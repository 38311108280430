/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useState, useMemo, useRef, useContext } from "react";

import { LoginContext } from '../../context/login-context';

import VOCClient from "../../VOCClient";

import CustomDatePicker from "../../components/form/custom-date-picker";
import CustomSelect from "../../components/form/custom-select/custom-select";
import {
  CustomButton,
  Header,
  Scrubber,
  // ImageCard,
} from "../../components";
import { ImageCard } from "../../components/image-card/image-card";
import CameraIntervalDialog from "./CameraIntervalDialog";
import LiveViewModal from "./LiveViewModal";

import useFarmField from '../../hooks/use-farm-field';

import ImageCache from '../../ImageCache';
import { ReactGAContext } from '../../context/react-ga';


import moment from "moment";

import { css } from "./css";

const imageCache = new ImageCache();
const Home = () => {
  const { sendEvent } = useContext(ReactGAContext);

  // State
  const [open, setOpen] = useState(true);
  const [openInterval, setOpenInterval] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedCameraId, setSelectedCameraId] = useState(null);
  const [openLiveView, setOpenLiveView] = useState(false);
  const [liveImages, setLiveImages] = useState({});
  const [isLoadingSelectedTime, setIsLoadingSelectedTime] = useState(true);

  const { runProtected, state: userData } = useContext(LoginContext);

  const {
    setSelectedCustomerId,
    setSelectedFarmId,
    setSelectedFieldId,
    selectedFarm,
    selectedField,
    selectedCustomerId,
    images,
    refetchImages,
    scrubberMarkers,
    startRange,
    setStartRange,
    endRange,
    setEndRange,
    refetchCustomer,
    customer,
    customers,
    isLoading
  } = useFarmField();

  const fieldCameras = selectedField?.cameras || [];
  const selectedCamera = fieldCameras?.find((c) => c.id === selectedCameraId);

  useEffect(() => {
    refetchImages();

    const interval = setInterval(() => {
      refetchImages();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchImages]);

  useEffect(() => {
    if (selectedTime) {
      return;
    }

    setSelectedTime(scrubberMarkers[scrubberMarkers.length - 1]?.value || null);
    setIsLoadingSelectedTime(false);
  }, [scrubberMarkers, selectedTime]);

  useEffect(() => {
    setSelectedTime(null);
    setIsLoadingSelectedTime(true);
  }, [startRange, endRange, selectedField?.id]);

  const handleCustomBoxClick = (camera) => {
    sendEvent({
      category: 'configuration',
      action: 'image_expand',
      value: camera.name
    });
  };

  useEffect(() => {
    if (!selectedField) {
      return;
    }

    async function updateImages() {
      setLiveImages(selectedField.cameras.reduce((map, c) => {
        map[c.id] = {
          img: null,
          loading: true,
          error: false
        }

        return map;
      }, {}));

      selectedField.cameras.forEach(async (c) => {
        const { img, error } = await runProtected(
          async () => imageCache.getImageBlob(c.id, `/api/camera/new-image/${c.id}`),
        );

        setLiveImages((map) => ({
          ...map,
          [c.id]: {
            loading: false,
            img,
            error
          }
        }));
      });
    }

    updateImages();
  }, [selectedField, runProtected]);

  function handleCustomerSelect(e) {
    const { value } = e.target;

    if (!value) return;
    
    sendEvent({
      category: 'filter',
      action: 'select_customer',
      label: customers?.find(c => c.id === value)?.name
    });

    setSelectedCustomerId(value);
  }

  function handleSelectFarm(e) {
    const { value } = e.target;

    if (!value) return;

    sendEvent({
      category: 'filter',
      action: 'select_farm',
      label: customer?.farms?.find(f => f.id === value)?.name
    });

    setSelectedFarmId(value);
  }

  function handleSelectField(e) {
    const { value } = e.target;

    if (!value) return;

    sendEvent({
      category: 'filter',
      action: 'select_field',
      label: selectedFarm?.fields?.find(f => f.id === value)?.name
    });

    setSelectedFieldId(value);
  }

  function handleDateStart(v) {
    if (!v) return;

    sendEvent({
      category: 'datetime',
      action: 'start_range',
      label: v
    });

    setStartRange(v);
  }

  function handleDateEnd(v) {
    if (!v) return;

    sendEvent({
      category: 'datetime',
      action: 'end_range',
      label: v
    });

    setEndRange(v);
  }

  function handleClearRange() {
    sendEvent({
      category: 'datetime',
      action: 'clear_range'
    });

    setStartRange("");
    setEndRange("");
    refetchImages();
  }

  function handleViewLive(id) {
    sendEvent({
      category: 'livestreaming',
      action: 'view_live',
      label: fieldCameras?.find((c) => c.id === id)?.name
    });

    setSelectedCameraId(id);
    setOpenLiveView(true);
  }

  const header = <Header showUser={true} />;

  const farmOptions = customer?.farms.map((farm) => ({
    id: farm.id,
    value: farm.id,
    name: farm.name,
  }));

  const fieldOptions = selectedFarm?.fields?.map((field) => ({
    id: field.id,
    value: field.id,
    name: field.name,
  }));

  const scrubberMin = scrubberMarkers[0]?.value;
  const scrubberMax = scrubberMarkers[scrubberMarkers.length - 1]?.value;

  const btnCaptureIntervals = (
    <CustomButton
      label="Set Capture Intervals"
      variant="outlined"
      onClick={() => setOpenInterval(true)}
      align="right"
      style={css.btnCaptureIntervals}
      labelStyle={css.btnCaptureIntervals.label}
    />
  );

  const customerSelect = userData.admin
    ? (
      <CustomSelect
        label="Select Customer"
        value={selectedCustomerId}
        variant="outlined"
        onChange={handleCustomerSelect}
        options={customers}
      />
    )
    : null;

  const controls = (
    <div css={css.controls}>
      {customerSelect}

      <CustomSelect
        label="Select Farm"
        value={selectedFarm?.id}
        variant="outlined"
        onChange={handleSelectFarm}
        options={farmOptions}
      />

      <CustomSelect
        label="Select Field"
        value={selectedField?.id}
        variant="outlined"
        onChange={handleSelectField}
        options={fieldOptions}
      />

      <CustomDatePicker
        value={startRange}
        onChange={handleDateStart}
        onBlur={() => refetchImages()}
        name="range_start"
        label="Range Start"
        max={endRange}
        datetime
      />

      <CustomDatePicker
        value={endRange}
        onChange={handleDateEnd}
        onBlur={() => refetchImages()}
        name="range_end"
        label="Range End"
        min={startRange}
        datetime
      />

      <CustomButton
        label="Clear Range"
        variant="outlined"
        onClick={handleClearRange}
        align="left"
        style={css.btnCaptureIntervals}
        labelStyle={css.btnCaptureIntervals.label}
      />

      {btnCaptureIntervals}
    </div>
  );

  const dialog = (
    <CameraIntervalDialog
      selectedField={selectedField}
      open={openInterval}
      setOpen={setOpenInterval}
      onSuccess={refetchCustomer}
    />
  );

  const scrubber = (
    <div css={css.scrubber}>
      <Scrubber
        leftLabel="Oldest"
        rightLabel="Newest"
        min={scrubberMin}
        max={scrubberMax}
        markerValue={selectedTime || scrubberMax}
        bufferPosition={75}
        valueLabelDisplay="auto"
        markers={scrubberMarkers}
        setSelectedTime={setSelectedTime}
      />
    </div>
  );

  const cameras =
    selectedField?.cameras &&
    selectedField?.cameras?.length > 0 &&
    selectedField?.cameras.map((camera, index) => {
      return (
        <ImageCard
          key={camera.id}
          title={camera.name}
          camera={camera}
          images={images?.filter(i => i.camera_id === camera?.id)}
          liveImage={liveImages[camera.id]}
          selectedTime={selectedTime}
          setSelectedCameraId={handleViewLive}
          selectBox={handleCustomBoxClick}
          isLoading={isLoading || isLoadingSelectedTime}
        />
      );
    });

  const camerasBlock = <div css={css.camerasBlock}>{cameras}</div>;

  const content = (
    <div css={css.content}>
      {controls}
      {scrubber}
      {camerasBlock}
      {openInterval && dialog}
    </div>
  );

  return (
    <main css={css.main}>
      {header}
      {content}

      <LiveViewModal
        open={openLiveView}
        setOpen={setOpenLiveView}
        camera={selectedCamera}
        liveImage={liveImages[selectedCamera?.id]}
        timeToStayOpen={600000}
      />
    </main>
  );
};

export default Home;
