/** @jsxImportSource @emotion/react */

import { useContext } from 'react';

import { useNavigate } from "react-router-dom";

import {
	fontbox,
	paddingX,
	paddingY,
	theme,
} from "../../emotion/utils";

import VOCClient from "../../VOCClient";

import { LoginContext } from '../../context/login-context';

// import { IconUser } from "../icons/icon-user";
import logo from "../logo/valley-logo.png";

import { CustomButton } from "../custom-button/custom-button";

import { css } from "./css";

export const Header = ({ showUser = false }) => {
	const navigate = useNavigate();

	const { logout } = useContext(LoginContext);

	const handleUserClick = (e) => {
		e.preventDefault();
		logout();
		navigate("/login");
	};

  const logoutBtn = (
    <CustomButton
      label="Logout"
      onClick={handleUserClick}
      align="right"
      isFullWidth={false}
      style={{
        ...paddingX(15),
        ...paddingY(0),
        height: 30
      }}
      labelStyle={{
        ...fontbox(theme.fonts.inter, 14, 500, 20),
      }}
    />
  );

	return (
		<header css={css.header}>
			<div css={css.container}>
				<img src={logo} alt="logo" />
				{/* {showUser && user} */}
        {logoutBtn}
			</div>
		</header>
	);
};
