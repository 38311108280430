/** @jsxImportSource @emotion/react */

import { useState } from "react";

import { CustomButton } from "../../components/custom-button/custom-button";

import VOCClient from "../../VOCClient";

import {
	borderRadius,
	flexbox,
	fontbox,
	fullWidth,
	paddingX,
	paddingY,
	posStatic,
	theme,
} from "../../emotion/utils";

const ForgotPasswordForm = (props) => {
	const { setOpen } = props;

	const [hasError, setHasError] = useState(false);

	const handlePasswordReset = async () => {
		try {
			let email = document.getElementById("email").value;

			const response = await VOCClient.forgotPassword(email);
			const status = response["response"]["status"];
			if (status > 299) {
				throw new Error(
					"Check your email for a link to reset your password!"
				);
			} else {
				alert(
					"Please check your email for a link to reset your password."
				);
			}
		} catch (err) {
			console.log(err);
			alert(
				"Error occured trying to send password reset email, the email was not sent"
			);
		}
	};

	return (
		<div css={css.container}>
			<div css={css.container.fieldset}>
				<div css={css.container.fieldset.intro}>
					If you have forgotten your password you can reset it here.
				</div>

				<div css={css.container.fieldset.formItem}>
					<label>Email</label>
					<input id="email" name="email" />
				</div>

				<CustomButton
					label="Send My Password"
					onClick={handlePasswordReset}
					style={{ marginTop: 20 }}
				/>

				<CustomButton
					label="Back to Login"
					color="primary"
					variant="outlined"
					onClick={() => {
						setHasError(false);
						setOpen(false);
					}}
					style={{
						...paddingX(0),
						...paddingY(0),
						border: "none",
						flex: "unset",
						height: "auto",
						width: "auto",
					}}
					labelStyle={{
						...fontbox(theme.fonts.inter, 12, 600, 20),
					}}
				/>
			</div>
		</div>
	);
};

const css = {
	container: {

		fieldset: {
			...flexbox("column", "center", "flex-end", 20),
			...fullWidth(),
			border: "none",
			margin: 0,
			padding: 0,

			"> *": {
				...fullWidth(),
			},

			intro: {
				...fontbox(theme.fonts.inter, 14, 600, 22),
				textAlign: "center",

        "@media (min-width: 576px)": {
          ...fontbox(theme.fonts.inter, 16, 600, 24),
        },
			},

			formItem: {
				...flexbox("column", "flex-start", "flex-start", 3),

        "@media (min-width: 576px)": {
          gap: 6,
        },

				label: {
          ...fontbox(theme.fonts.inter, 12, 500, 18),
          ...posStatic(),
          color: theme.colors.textSecondary,
          letterSpacing: 0,
          maxWidth: "none",
          transform: "none",

          "@media (min-width: 576px)": {
            ...fontbox(theme.fonts.inter, 14, 500, 20),
          },
				},

				input: {
					...borderRadius(8),
					...fullWidth(),
					border: `1px solid ${theme.colors.borderPrimary}`,
			    boxShadow: `0 1px 2px 0 ${theme.colors.textPrimary}05`,
					height: 56,
					padding: 10,
				},
			},
		},
	},
};

export default ForgotPasswordForm;
