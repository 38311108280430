/** @jsxImportSource @emotion/react */

import { useEffect, useState, useCallback, useContext } from 'react';
import { Typography, Box } from '@mui/material';

import VideoPlayer from '../../components/video-player/videoplayer'
import { CustomDialog } from '../../components';

import { ReactGAContext } from '../../context/react-ga';

export default function LiveViewModal(props) {
  const { sendEvent } = useContext(ReactGAContext);

  const {
    open,
    setOpen,
    camera,
    timeToStayOpen,
    liveImage
  } = props;

  const closeLiveView = useCallback((action) => {
    return () => {
      sendEvent({
        category: 'live_view',
        action,
        label: camera?.name
      });

      setOpen(false);
    }
  }, [camera, setOpen, sendEvent]);

  const initStatus = "Real times snapshot, loading camera stream";
  const [status, setStatus] = useState(initStatus);

  const wsUrl = window.location.hostname === 'localhost'
    ? 'ws://localhost:3001'
    : `wss://${window.location.host}`

  useEffect(() => {
    if (!open) return;

    const closeTimer = setTimeout(() => {
      clearTimeout(closeTimer);
      closeLiveView('livestream_end')();
    }, timeToStayOpen);

    return () => {
      clearTimeout(closeTimer);
    };
  }, [open, timeToStayOpen, closeLiveView])

  const contents = open && camera
    ? (
      <VideoPlayer
        url={camera?.id ? `${wsUrl}/api/camera/${camera?.id}` : null} 
        status={status}
        setStatus={setStatus}
        id={camera?.id}
        liveImage={liveImage?.img}
      />
    )
    : (
      <Typography>Loading...</Typography>
    );

  return (
    <CustomDialog
      flexDirection="column"
      open={open}
      onClose={closeLiveView('livestream_closed')}
      title={camera?.name}
      dialogCss={css.dialog}
    >
      <Typography>{status}</Typography>
      <Box>
        {contents}
      </Box>
    </CustomDialog>
  );
}

const css = {
  dialog: {
    height: "auto",
  }
};
