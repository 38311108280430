import {
	borderRadius,
	flexbox,
	imageBox,
	marginAuto,
	paddingX,
	paddingY,
	theme,
} from "../../emotion/utils";

export const css = {
	header: {
		backgroundColor: theme.colors.white,
		borderBottom: `1px solid ${theme.colors.borderTertiary}`,
	},

	container: {
		...flexbox("row", "center", "space-between", 20),
		...marginAuto(),
		...paddingY(20),
		maxWidth: "90%",

		"@media screen and (min-width: 1200px)": {
			...paddingX(32),
			maxWidth: "92%",
		},

		img: {
			...imageBox("auto", 86, "100%"),

			"@media screen and (min-width: 768px)": {
				...imageBox("auto", 142, "100%"),
			},
		},
	},

	user: {
		...borderRadius("50%"),
		transition: "background-color 0.2s",

		svg: {
			display: "block",
		},

		"&:hover": {
			backgroundColor: `${theme.colors.valleyLight}50`,
		},
	},
};
