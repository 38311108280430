/** @jsxImportSource @emotion/react */

import { useEffect, useState, useContext } from "react";
import { InputAdornment } from "@mui/material";
import { Formik } from 'formik';

import VOCClient from "../../VOCClient";
import CustomTextField from "../../components/form/custom-text-field/custom-text-field";
import { CustomDialog } from "../../components";
import { borderRadius, flexbox, fontbox, fullWidth, paddingX, paddingY, theme } from "../../emotion/utils";

import { LoginContext } from '../../context/login-context';
import { ReactGAContext } from '../../context/react-ga';

import useProtectedMutation from '../../hooks/use-protected-mutation';

const INTERVALS = [10, 20, 30, 40, 50, 60];

const CameraIntervalDialog = (props) => {
  const { sendEvent } = useContext(ReactGAContext);

  const {
    selectedField,
    open,
    setOpen,
    onSuccess
  } = props;

  const handleClose = () => setOpen(false);

  const { mutate: handleSave } = useProtectedMutation({
    mutationFn: async (values) => {
      const intervalMap = selectedField?.cameras?.reduce((map, c) => {
        map[c.id] = c.interval;
        return map;
      }, {}) || {};

      const payload = values.cameras
        .map((c) => ({
          id: c.id,
          name: c.name,
          interval: c.interval
        }))
        .filter((c) => intervalMap[c.id] !== c.interval);

      await VOCClient.saveCamera(payload);

      payload.forEach((c) => {
        sendEvent({
          category: 'configuration',
          action: 'set_intervals',
          label: c.name,
          value: c.interval
        });
      });

      onSuccess();

      setOpen(false);
    }
  });

  const initialValues = {
    cameras: selectedField?.cameras?.map((c) => ({ ...c }))
  };

  const noneInterval = (
    <option value="">None</option>
  );

  const currentInterval = (int) => {
    if (INTERVALS.find(i => i === int)) {
      return null;
    }

    return (
      <option value={int}>
        {int} minutes
      </option>
    );
  }

  const intervalEls = (currentVal) => {
    const intervals = [...INTERVALS];
    if (currentVal) {
      intervals.push(currentVal);
    }

    return Array.from(new Set(intervals))
      .sort((a, b) => a - b)
      .map((int) => (
        <option key={int} value={int}>
          {int} minutes
        </option>
      ));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      enableReinitialize
    >
      {(formik) => (
        <CustomDialog
          flexDirection="row"
          open={open}
          onClose={handleClose}
          title="Capture Images Every..."
          actions={[
            {
              label: "Cancel",
              onClick: handleClose,
              variant: "outlined",
              color: "primary"
            },
            {
              label: "Confirm",
              onClick: formik.handleSubmit,
              variant: "contained",
              color: "primary"
            }
          ]}
          width={400}
        >
          <div style={css.holder}>
            {formik.values.cameras?.map((c, i) => (
              <div key={i} css={css.select}>
                <label>{c?.name}</label>
                <select
                  key={c?.id}
                  onChange={(e) => {
                    const newVal = e.target.value ? parseInt(e.target.value) : null;
                    formik.setFieldValue('cameras', [
                      ...formik.values.cameras.slice(0, i),
                      { ...c, interval: newVal },
                      ...formik.values.cameras.slice(i + 1),
                    ]);
                  }}
                  style={{ width: "46%" }}
                  value={c.interval || ''}
                >
                  {noneInterval}
                  {intervalEls(c.interval)}
                </select>
              </div>
              // <CustomTextField
              //   key={c?.id}
              //   label={c?.name}
              //   value={c.interval}
              //   onChange={(e) => {
              //     formik.setFieldValue('cameras', [
              //       ...formik.values.cameras.slice(0, i),
              //       { ...c, interval: parseInt(e.target.value) },
              //       ...formik.values.cameras.slice(i + 1),
              //     ]);
              //   }}
              //   type="number"
              //   InputProps={{
              //     endAdornment: (
              //       <InputAdornment position="end">minutes</InputAdornment>
              //     )
              //   }}
              //   width="46%"
              // />
            ))}
          </div>
        </CustomDialog>
      )}
    </Formik>
  );
}

const css = {
  holder: {
    ...flexbox("row", "flex-start", "space-between", 25, "wrap"),
    ...fullWidth()
  },

  select: {
    ...flexbox("column", "flex-start", "flex-start", 2),
    width: "45%",

    "> *": {
      width: "100%!important"
    },

    label: {
      ...fontbox(theme.fonts.inter, 14, 600, 20),
      color: theme.colors.black,

      "@media screen and (min-width: 992px)": {
        ...fontbox(theme.fonts.inter, 11, 600, 17),
      },
      "@media screen and (min-width: 1200px)": {
        ...fontbox(theme.fonts.inter, 14, 600, 20),
      },
    },

    "select": {
      ...borderRadius(8),
      ...fontbox(theme.fonts.inter, 14, 600, 40),
      ...paddingX(16),
      ...paddingY(0),
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.borderPrimary}`,
      borderSizing: "border-box",
      boxShadow: `0 1px 2px 0 ${theme.colors.textPrimary}05`,
      height: 40,

      "@media screen and (min-width: 992px)": {
        ...fontbox(theme.fonts.inter, 11, 600, 40),
        ...paddingX(8),
      },
      "@media screen and (min-width: 1200px)": {
        ...fontbox(theme.fonts.inter, 14, 600, 40),
        ...paddingX(16),
      },
    },
  },
};

export default CameraIntervalDialog;