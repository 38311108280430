import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider as JotaiProvider } from "jotai";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LoginProvider } from "./context/login-context";
//import { ThemeProvider } from "@mui/material";

// import { light as lightTheme } from './layouts/default'
// import { theme } from "./emotion/utils";

import ErrorBoundary from "./components/error-boundary/error-boundary";
import NotFound from "./pages/not-found/not-found";

import Protected from './layouts/protected';
import Login from "./pages/login/login";
import Home from "./pages/main/main";
import ResetPassword from './pages/login/reset-password';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { ReactGAProvider } from './context/react-ga';

function App() {
  const queryClient = new QueryClient();

  const routes = (
    <Routes>
      <Route path="/" element={<Protected />}>
        <Route exact path="/" element={<Home />} />
      </Route>

      <Route exact path="/login" element={<Login />} />

      <Route path="/reset-password" element={<ResetPassword />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
  
  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <LoginProvider>
          <ReactGAProvider>
            <JotaiProvider>
              <QueryClientProvider client={queryClient}>
                {/* <ThemeProvider theme={lightTheme}></ThemeProvider> */}
                <BrowserRouter>
                  {routes}
                </BrowserRouter>{" "}
              </QueryClientProvider>
            </JotaiProvider>
          </ReactGAProvider>
        </LoginProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

export default App;
