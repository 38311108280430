import React, { useContext, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";

import { LoginContext } from "../context/login-context";

const LOGIN_PATH = "/login";

function ProtectedLayout() {
  const { state } = useContext(LoginContext);

  useEffect(() => {
    ReactGA.set({
      user_id: state?.id,
      customer_id: state?.customer_id,
      customer_name: state?.customer?.name,
      is_admin: state?.admin?.toString()
    });
  }, [state]);

  if (!state?.id) {
    return <Navigate to={LOGIN_PATH} replace></Navigate>;
  }

  return <Outlet />;
}

export default ProtectedLayout;
