import {
	borderRadius,
	flexbox,
	fontbox,
	fullWidth,
	paddingX,
	paddingXY,
	posAbsolute,
	posRelative,
	square,
	theme,
} from "../../emotion/utils";

export const css = (width) => ({
  ...borderRadius("12px"),
  ...flexbox("column", "center", "center", 0),
  ...fullWidth(),
  backgroundColor: theme.colors.white,
  boxShadow: `0 4px 8px -2px ${theme.colors.textPrimary}10, 0px 2px 4px -2px ${theme.colors.textPrimary}06`,
  minHeight: 200,
  overflow: "hidden",

  "@media screen and (min-width: 768px)": {
    width,
  },

  img: (width,) => ({
    ...flexbox("column", "center", "center", 0),
    ...fullWidth(),
    ...posRelative(),
    backgroundColor: `${theme.colors.black}12`,
    flex: 1,
    overflow: "hidden",

    "> img": {
      height: "auto",
      width,
    }
  }),

  controls: {
    ...posAbsolute(null, 5, 5, null, 10),

    "@media screen and (min-width: 576px)": {
      ...posAbsolute(null, 10, 10, null, 10),
    },

    "> button": {
      ...paddingXY(8),
      backgroundColor: `rgba(255,255,255,0.75)!important`,
      borderColor: `${theme.colors.textPrimary}75!important`,
      minWidth: `unset!important`,

      "> svg": {
        ...square(12),

        "@media screen and (min-width: 576px)": {
          ...square(16),
        },

        "> path": {
          fill: `${theme.colors.textPrimary}!important`,
          stroke: `${theme.colors.textPrimary}!important`,
        },
      },

      "&:nth-of-type(3)": {
        display: "none",

        "@media screen and (min-width: 768px)": {
          display: "inline-flex",
        },
      },
    },
  },

  content: {
    ...flexbox("column", "center", "flex-start", 16),
    // ...fullHeight(),
    ...fullWidth(),
    ...posRelative(),
    padding: 16,

    // "@media screen and (min-width: 1200px)": {
    // },
    "@media screen and (min-width: 1200px)": {
      ...flexbox("row", "center", "space-between", 16, "nowrap"),
    },

    "> *": {
      ...fullWidth(),
    },

    "> button": {
      ...fullWidth(),
      ...paddingX(10),
      textWrap: "nowrap",

      "@media screen and (min-width: 1200px)": {
        marginLeft: "auto",
        maxWidth: "unset",
        width: "145px!important",
      },
      // "@media screen and (min-width: 1200px)": {
      // },
    },

    bottom: {
      ...flexbox("row", "center", "center", 15),

      "@media screen and (min-width: 1200px)": {
        // ...flexbox("row", "center", "flex-end"),
        maxWidth: "none",
        width: "auto!important",
      },

      "> label": {
        "@media screen and (min-width: 1200px)": {
          // marginRight: 50,
          width: "auto!important",
        },
      },

      // expand: {
      //   display: "none",

      //   "@media screen and (min-width: 768px)": {
      //     ...paddingXY(15),
      //     ...posAbsolute(null, 0, 0, null, 1),
      //     cursor: "pointer",
      //     display: "block",
      //     width: "auto!important",
      //   },
      //   "@media screen and (min-width: 1200px)": {
      //     bottom: "unset",
      //   },

      //   "> svg": {
      //     display: "block",
      //   },
      // },
    },

    "& label": {
      ...fontbox(theme.fonts.inter, 16, 600, 24),
      textAlign: "center",

      "@media screen and (min-width: 1200px)": {
        textAlign: "left",
        textWrap: "nowrap",
        // width: "57%!important",
      },
      "@media screen and (min-width: 1400px)": {
        width: "100%!important",
      },
    },
  },
});