import {
	borderRadius,
	flexbox,
	fontbox,
	fullHeight,
	fullWidth,
	marginAuto,
	paddingX,
	paddingXY,
	paddingY,
	posAbsolute,
	posRelative,
	theme,
} from "../../emotion/utils";

export const css = {
	main: {
		...flexbox("column", "center", "flex-start"),
		...fullHeight(),
		backgroundColor: theme.colors.bgSecondary,
		paddingTop: 65,
	},

	form: {
		...borderRadius(12),
		...flexbox("column", "center", "center", 20),
		...paddingX(20),
		...paddingY(30),
		...posRelative(),
		backgroundColor: theme.colors.white,
		boxShadow:
			"0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
		maxWidth: 400,
		width: "90%",

		"@media (min-width: 576px)": {
			...paddingXY(60),
			gap: 40,
		},

		img: {
			...fullWidth(),
			height: "auto",
			maxWidth: 100,

			"@media (min-width: 576px)": {
				maxWidth: 200,
			},
		},
	},

	fieldset: (display) => ({
		...flexbox("column", "center", "center", 20),
		...fullWidth(),
		display: display ? "none" : "flex",

		"> *, label": {
			...fullWidth(),
		},

		title: {
			...fontbox(theme.fonts.inter, 16, 600, 26),
			color: theme.colors.textPrimary,
			textAlign: "center",

			"@media (min-width: 576px)": {
				fontSize: 18,
				lineHeight: "28px",
			},
		},

		fields: {
			...flexbox("column", "center", "center", 20),
			...fullWidth(),
			flexDirection: "column",
			alignItems: "flex-start",
			gap: 10,
			justifyContent: "flex-start",

			"> div": {
				...fullWidth(),
			},
		},
	}),

	errorBlock: {
		...flexbox("column", "center", "center"),
		...fullWidth(),
		...marginAuto(),
		...paddingX(20),
		...paddingY(5),
		...posAbsolute(null, 0, 15, 0),
		backgroundColor: `${theme.colors.negativeRed}15`,
		border: `1px solid ${theme.colors.negativeRed}30`,
		borderRadius: 8,
		marginTop: 20,
		maxWidth: 280,

		text: {
			...fontbox(theme.fonts.inter, 12, 500, 16),
			color: theme.colors.negativeRed,
		},
	},
};
