export const theme = {
	colors: {
		transparent: "transparent",

		black: "#000000",
		white: "#ffffff",

		bgSecondary: "#F9FAFB",

		consoleBlue: "#263344",
		highlightBlue: "#20363D",
		darkBlue: "#1C2229",

		placeholderGray: "#737373",

		negativeRed: "#A00D06",

		borderPrimary: "#D0D5DD",
		borderSecondary: "#98A2B3",
		borderTertiary: "#EAECF0",

		slider: "#02518D",

		textLight: "#E5F7FD",
		textDark: "#282828",
		textPrimary: "#101828",
		textSecondary: "#344054",

		valleyLight: "#77C9E4",
		valleyMed: "#3075A4",
		valleyDark: "#205482",
	},

	btnColors: {
		primary: "#02518D",
		secondary: "#D0D5DD",
		tertiary: "#E5F7FD",
	},

	bp: [576, 768, 992, 1200],

	fonts: {
		inter: "Inter, sans-serif",
	},
};

// FUNCTIONS ---------------------------------------------

export const getAlign = (align) => {
	const alignments = {
		left: { flex: "unset", marginLeft: 0, marginRight: "auto" },
		right: { flex: "unset", marginLeft: "auto", marginRight: 0 },
		center: { ...marginAuto() },
	};

	return alignments[align] || {};
};

export const getButton = (mode, color) => {
	const getBgColor =
		mode === "contained"
			? { backgroundColor: theme.btnColors[color] }
			: { backgroundColor: theme.colors.white };

	const getBorderColor =
		mode === "text"
			? { borderColor: theme.colors.transparent }
			: { borderColor: theme.btnColors[color] };

	return { ...getBgColor, ...getBorderColor };
};

export const getButtonLabel = (mode, type) => {
	const getColor =
		mode === "contained"
			? { color: theme.colors.white }
			: { color: theme.btnColors[type] };

	return { ...getColor };
};

export const getButtonColor = (color) => {
	return theme.btnColors[color];
};

// MIXINS ------------------------------------------------

// Border Radius
export const borderRadius = (rad) => {
	const borderRadius = rad || 0;

	return { borderRadius };
};
export const borderTopLeftRadius = (rad) => {
	const borderTopLeftRadius = rad || 0;

	return { borderTopLeftRadius };
};
export const borderTopRightRadius = (rad) => {
	const borderTopRightRadius = rad || 0;

	return { borderTopRightRadius };
};
export const borderBottomLeftRadius = (rad) => {
	const borderBottomLeftRadius = rad || 0;

	return { borderBottomLeftRadius };
};
export const borderBottomRightRadius = (rad) => {
	const borderBottomRightRadius = rad || 0;

	return { borderBottomRightRadius };
};

// Flexbox
export const flexbox = (fd, ai, jc, gp, fw) => {
	const alignItems = ai === undefined || ai === null ? "center" : ai;
	const display = "flex";
	const flexDirection = fd === undefined || fd === "column" ? "column" : fd;
	const gap = gp === undefined || gp === null ? 8 : gp;
	const justifyContent = jc === undefined || jc === null ? "center" : jc;
	const flexWrap = fw === undefined || fw === null ? null : fw;

	return {
		alignItems,
		display,
		flexDirection,
		flexWrap,
		gap,
		justifyContent,
	};
};

// Fontbox
export const fontbox = (ff, fs, fw, lh) => {
	const fontFamily = ff === undefined || ff === null ? theme.fonts.inter : ff;
	const fontSize = fs === undefined || fs === null ? 14 : fs;
	const fontWeight = fw === undefined || fw === null ? "400" : `${fw}`;
	const lineHeight =
		lh === undefined || lh === null ? `${fs + 2}px` : `${lh}px`;

	return { fontFamily, fontSize, fontWeight, lineHeight };
};

// Full Height
export const fullHeight = () => {
	const height = "100%";

	return { height };
};

// Full Width
export const fullWidth = () => {
	const width = "100%";

	return { width };
};

// Image Box
export const imageBox = (h, maxW, w) => {
  const height = h || "auto";
  const maxWidth = maxW || "100%";
  const width = w || fullWidth();

  return { height, maxWidth, width };
};

// Margin
export const margin = (t, r, b, l) => {
	const marginTop = t || null;
	const marginRight = r || null;
	const marginBottom = b || null;
	const marginLeft = l || null;

	return { marginTop, marginRight, marginBottom, marginLeft };
};
export const marginY = (num) => {
	const marginTop = num !== undefined ? num : null;
	const marginBottom = num !== undefined ? num : null;

	return { marginTop, marginBottom };
};
export const marginX = (num) => {
	const marginRight = num !== undefined ? num : null;
	const marginLeft = num !== undefined ? num : null;

	return { marginRight, marginLeft };
};

// Margin Auto
export const marginAuto = () => {
	const margin = "0 auto";

	return { margin };
};

// Object Contain
export const objectContain = () => {
  const objectFit = "contain";
  const maxHeight = "100%";
  const maxWidth = "100%";

  return { objectFit, maxHeight, maxWidth };
};

// Padding
export const padding = (t, r, b, l) => {
	const paddingTop = t !== undefined ? t : null;
	const paddingRight = r !== undefined ? r : null;
	const paddingBottom = b !== undefined ? b : null;
	const paddingLeft = l !== undefined ? l : null;

	return { paddingTop, paddingRight, paddingBottom, paddingLeft };
};
export const paddingY = (num) => {
	const paddingTop = num !== undefined ? num : null;
	const paddingBottom = num !== undefined ? num : null;

	return { paddingTop, paddingBottom };
};
export const paddingX = (num) => {
	const paddingRight = num !== undefined ? num : null;
	const paddingLeft = num !== undefined ? num : null;

	return { paddingRight, paddingLeft };
};
export const paddingXY = (num) => {
  const padding = num !== undefined ? num : null;

  return { padding };
}

export const position = (pos, topPos, rightPos, bottomPos, leftPos, z) => {
	const position = pos !== undefined ? pos : "relative";
	const top = topPos !== undefined ? topPos : null;
	const right = rightPos !== undefined ? rightPos : null;
	const bottom = bottomPos !== undefined ? bottomPos : null;
	const left = leftPos !== undefined ? leftPos : null;
	const zIndex = z !== undefined ? z : null;

	return { position, top, right, bottom, left, zIndex };
};
// Alias functions for position
export const posAbsolute = (topPos, rightPos, bottomPos, leftPos, z) => {
	return position("absolute", topPos, rightPos, bottomPos, leftPos, z);
};
export const posRelative = (topPos, rightPos, bottomPos, leftPos, z) => {
	return position("relative", topPos, rightPos, bottomPos, leftPos, z);
};
export const posFixed = (topPos, rightPos, bottomPos, leftPos, z) => {
	return position("fixed", topPos, rightPos, bottomPos, leftPos, z);
};
export const posSticky = (topPos, rightPos, bottomPos, leftPos, z) => {
	return position("sticky", topPos, rightPos, bottomPos, leftPos, z);
};
export const posStatic = () => {
	return position("static");
};

export const square = (size) => {
	const width = size ? size : null;
	const height = size ? size : null;

	return { width, height };
};
