import {
  borderRadius,
	flexbox,
	fontbox,
	fullHeight,
	fullWidth,
	marginAuto,
	paddingX,
	paddingXY,
	paddingY,
	theme,
} from "../../emotion/utils";

export const css = {
	main: {
		backgroundColor: theme.colors.bgSecondary,
		minHeight: "100%",
	},

	content: {
		...flexbox("column", "flex-start", "flex-start", 20),
		...marginAuto(),
		...paddingY(20),
		maxWidth: "90%",

    "@media screen and (min-width: 1200px)": {
      ...paddingX(32),
      maxWidth: "92%",
    },
	},

	controls: {
		...flexbox("column", "flex-start", "flex-start", 15),
		...fullWidth(),

    "@media screen and (min-width: 576px)": {
		  ...flexbox("row", "flex-end", "space-between", 25, "wrap"),
    },
    "@media screen and (min-width: 992px)": {
      ...flexbox("row", "flex-end", "flex-start", 16, "nowrap"),
    },

    "> *": {
      ...fullWidth(),

      "@media screen and (min-width: 576px)": {
        width: "47%",
      },
      "@media screen and (min-width: 992px)": {
        flex: 1,
        maxWidth: 135,
        width: "unset",
      },
      "@media screen and (min-width: 1200px)": {
        flex: "unset",
        maxWidth: "unset",
      },
    },

    "> button": {
      ...paddingX(8),
      flex: "unset",

      "@media screen and (min-width: 992px)": {
        margin: 0,
      },
      "@media screen and (min-width: 1200px)": {
        ...paddingX(30),
      },

      "&:last-child": {
        marginLeft: "auto",
      },

      "span": {
        ...fontbox(theme.fonts.inter, 14, 600, 20),
        textWrap: "nowrap",

        "@media screen and (min-width: 992px)": {
          ...fontbox(theme.fonts.inter, 11, 600, 20),
        },
        "@media screen and (min-width: 1200px)": {
          ...fontbox(theme.fonts.inter, 14, 600, 20),
        },
      }
    },

		"> div": {
			"> div": {
				backgroundColor: theme.colors.white,
				border: `1px solid ${theme.colors.textLight}`,
				boxShadow: `0 1px 2px 0 ${theme.colors.textPrimary}05`,
				boxSizing: "border-box",
				height: 40,

				"> input": {
					...fullHeight(),
					border: 0,
				},

				"> fieldset": {
					border: "unset",
					boxShadow: "unset",
					top: 0,
				},

				"> button": {
					borderColor: "transparent",
				},
			},
		},
	},

	chipsBlock: {
		...flexbox("column", "flex-start", "flex-start", 2),
		marginLeft: 35,

		label: {
			...fontbox(theme.fonts.inter, 14, 600, 20),
			color: theme.colors.black,
		},

		chips: {
			...flexbox("row", "flex-start", "flex-start", 8),
		},
	},

	scrubber: {
    ...borderRadius(8),
		...fullWidth(),
    ...paddingXY(4),
		border: `1px solid ${theme.colors.textLight}`,
		marginTop: 25,

    "@media screen and (min-width: 768px)": {
      ...borderRadius(0),
      ...paddingXY(0),
      border: "none",
      marginTop: 35,
    },
	},

	camerasBlock: {
		...flexbox("row", "stretch", "space-between", 24, "wrap"),
		...fullWidth(),
    marginTop: 30,
	},

	btnCaptureIntervals: {
		...paddingX(16),
		...paddingY(0),
		border: `1px solid ${theme.colors.textLight}`,
		borderSizing: "border-box",
		boxShadow: `0 1px 2px 0 ${theme.colors.textPrimary}05`,
		height: 40,
		flex: "unset",

		label: {
			...fontbox(theme.fonts.inter, 14, 600, 38),
		},
	},
};
