import React, { useState, useEffect } from "react";
import Cookies from 'universal-cookie';

import VOCClient from '../VOCClient';

const LoginContext = React.createContext([{}, () => {}]);

const LoginProvider = (props) => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);

  async function runProtected(cb, ...args) {
    // refresh token if expired, then run callback
    if (state.exp < Date.now() / 1000) {
      try {
        const refreshToken = window.localStorage.getItem('refreshToken');
        const res = await VOCClient.refresh(refreshToken);
        saveUserData(res.body);
      } catch (err) {
        // invalid refresh
        logout();
        console.error(err);
      }
    }

    return cb(...args);
  }

  function logout() {
    // clear cookies and local data
    const cookies = new Cookies();
    Object.keys(cookies.getAll()).forEach((cookie) => {
      cookies.remove(cookie, { path: '/' });
    });

    window.localStorage.removeItem('loggedInUserId');
    window.localStorage.removeItem('userData');
    window.localStorage.removeItem('refreshToken');

    setState({});
  }

  function saveUserData(body) {
    const { userData, refreshToken } = body;
    const userId = userData.id;
    window.localStorage.setItem("userData", JSON.stringify(userData));
    if (refreshToken) {
      window.localStorage.setItem("refreshToken", refreshToken);
    }
    setState((state) => ({ ...userData }));
  }

  useEffect(() => {
    const hasOldAuth = window.localStorage.getItem("loggedInUserId");
    if (hasOldAuth) {
      logout();
    }

    let data = window.localStorage.getItem("userData");
    if (!data) {
      setState({});
      setLoading(false);
      return;
    }

    data = JSON.parse(data);
    setState(data);
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <LoginContext.Provider value={{
      state,
      setState,
      loading,
      logout,
      saveUserData,
      runProtected
    }}>
      {props.children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
