/** @jsxImportSource @emotion/react */

import { Field } from "formik";
import { TextField } from "formik-mui";
import {
	borderRadius,
	flexbox,
	fontbox,
	fullWidth,
	posStatic,
	theme,
} from "../../../emotion/utils";

const FormikTextField = ({
	label,
	labelShrink = false,
	type = "text",
	name,
}) => (
	<Field
		component={TextField}
		name={name}
		type={type}
		label={label}
		InputLabelProps={{ shrink: labelShrink }}
		css={css.field}
	/>
);

const css = {
	field: {
		...flexbox("column", "flex-start", "flex-start", 3),

		"@media (min-width: 576px)": {
			gap: 6,
		},

		"> *": {
			...fullWidth(),
		},

		label: {
			...fontbox(theme.fonts.inter, 12, 500, 18),
			...posStatic(),
			color: theme.colors.textSecondary,
			letterSpacing: 0,
			maxWidth: "none",
			transform: "none",

			"@media (min-width: 576px)": {
				...fontbox(theme.fonts.inter, 14, 500, 20),
			},
		},

		fieldset: {
			...borderRadius(8),
			borderColor: theme.colors.borderPrimary,
			boxShadow: `0 1px 2px 0 ${theme.colors.textPrimary}05`,
			overflow: "hidden",
			top: 0,

			legend: {
				display: "none",
			},
		},
	},
};

export default FormikTextField;
