/** @jsxImportSource @emotion/react */

import React, { useState, useEffect } from 'react';

import moment from "moment";
import { FormControl, TextField } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {
	borderRadius,
	flexbox,
	fontbox,
	fullWidth,
	paddingY,
	posAbsolute,
	posRelative,
	theme,
} from "../../../emotion/utils";
import CustomTextField from "../custom-text-field/custom-text-field";

export default function CustomDatePicker(props) {
	const {
		value,
		onChange,
		onBlur,
		onInput,
		name,
		label,
		datetime,
		min,
		max,
		readonly,
		helperText,
	} = props;

	const [val, setVal] = useState(null);

	useEffect(() => {
		setVal(value ? moment(value) : null);
	}, [value])

	function handleBlur() {
		if (onChange) onChange(val.toISOString());
		if (onBlur) onBlur();
	}

	return (
		<FormControl css={css.formControl}>
			{label && <label htmlFor={name}>{label}</label>}

			<DateTimePicker
				css={css.formControl.textField}
				id={name}
				name={name}
				onAccept={handleBlur}
				onChange={setVal}
				onInput={onInput}
				ampm={false}
				value={val}
				// min={minVal}
				// max={maxVal}
				readOnly={readonly ? true : false}
			/>

			{helperText && <div css={css.formControl.helper}>{helperText}</div>}
		</FormControl>
	);
}

const css = {
	formControl: {
		...flexbox("column", "flex-start", "flex-start", 2),
		...posRelative(),

		label: {
			...fontbox(theme.fonts.inter, 14, 600, 20),
			color: theme.colors.textSecondary,
			letterSpacing: 0,
			maxWidth: "none",
			transform: "none",

      "@media screen and (min-width: 992px)": {
        ...fontbox(theme.fonts.inter, 11, 600, 17),
      },
    "@media screen and (min-width: 1200px)": {
      ...fontbox(theme.fonts.inter, 14, 600, 20),
    },
		},

    textField: {
      ...fullWidth(),
      backgroundColor: `${theme.colors.white}!important`,
    },

		helper: {
			...fontbox(theme.fonts.inter, 9, 400, 9 * 1.5),
			...posAbsolute("100%"),
			color: theme.colors.textSecondary,
		},

		"> div": {
			// backgroundColor: "transparent!important",
			border: "none!important",
			boxShadow: "none!important",

			"> div": {
				...borderRadius(8),
				...fontbox(theme.fonts.inter, 14, 600, 40),
				border: `1px solid ${theme.colors.textLight}`,
				boxShadow: `0 1px 2px 0 ${theme.colors.textPrimary}05`,
				boxSizing: "border-box",
				height: "inherit",

        "@media screen and (min-width: 992px)": {
          ...fontbox(theme.fonts.inter, 11, 600, 40),
        },
        "@media screen and (min-width: 1200px)": {
          ...fontbox(theme.fonts.inter, 14, 600, 40),
          // ...paddingX(16),
        },

				input: {
					...paddingY(0),
					height: 40,
				},

				fieldset: {
					border: "none",
				},
			},
		},
	},
};
