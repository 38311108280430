import React from "react";
import { theme } from "../../emotion/utils";

export const IconExpand = ({
  fill = theme.colors.black,
  style,
}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={style}>
      <path d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15" stroke={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
