import React from 'react'
import { MapContainer, TileLayer, Rectangle } from 'react-leaflet'
import { LatLngBoundsExpression, LatLngTuple } from 'leaflet'

import 'leaflet/dist/leaflet.css'


export const Map = ({
  // position = [41.321564, -96.373261], // Real pivot
  position = [41, -96.373261],
  bounds = [
    [position[0] - 0.03, position[1] - 0.05],
    [position[0] + 0.01, position[1] + 0.01],
  ],
}) => (
  <MapContainer
    center={position}
    zoom={11}
    scrollWheelZoom={true}
    attributionControl={false}
    zoomControl={false}
    style={{
      height: '100%',
      width: '100%',
    }}
  >
    <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
    <Rectangle bounds={bounds} color="#77C9E4" fill={false} weight={3} />
  </MapContainer>
)
