import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";

import { LoginContext } from './login-context';

const { REACT_APP_GA_MEASUREMENT_ID } = process.env;

const ReactGAContext = React.createContext([{}, () => {}]);

const ReactGAProvider = (props) => {
  const { state: userData } = useContext(LoginContext);

  useEffect(() => {
    ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);
  }, []);

  const sendEvent = (data) => {
    const userParams = userData
      ? {
        customer_id: userData.customer_id,
        customer_name: userData.customer?.name,
        is_admin: userData.admin?.toString()
      }
      : {};

    // ReactGA.event({
    //   ...data,
    //   ...userParams
    // });

    ReactGA.event(data.action, { ...data, ...userParams });
  }

  return (
    <ReactGAContext.Provider value={{
      sendEvent
    }}>
      {props.children}
    </ReactGAContext.Provider>
  );
};

export { ReactGAContext, ReactGAProvider };
