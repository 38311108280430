/** @jsxImportSource @emotion/react */
import { css as emoCss } from "@emotion/react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { CustomButton } from "../custom-button/custom-button";

import { borderRadius, flexbox, fullWidth, paddingX, paddingXY, paddingY, posAbsolute, posRelative } from "../../emotion/utils";

export const CustomDialog = ({
  children,
  open,
  onClose,
  title,
  actions = [],
  width,
  headerClose = true,
  dialogCss,
}) => {
  const dialogTitle = (
    <DialogTitle sx={emoCss`${css.dialog().title(title ? 1 : 0)}`}>
      {title && title}
      {headerClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );

  const dialogContent = (
    <DialogContent sx={emoCss`${css.dialog().content}`}>
      {children}
    </DialogContent>
  );

  const dialogActions = actions && actions.length > 0 && (
    <DialogActions sx={emoCss`${css.dialog().actions}`}>
      {actions.map((action, index) => (
        <CustomButton
          key={index}
          {...action}
        />
      ))}
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={title || "dialog-title"}
      aria-describedby={title || "dialog-description"}
      sx={{
        ...emoCss`${css.dialog(width)}`
      }}
    >
      {dialogTitle}
      {dialogContent}
      {dialogActions}
    </Dialog>
  );
}

const css = {
  dialog: (width) => ({

    ".MuiDialog": {

      "&-paper": {
        ...flexbox("column", "flex-start", "flex-start", 12),
        ...borderRadius(12),
        ...paddingX(24),
        ...paddingY(24),
        margin: 0,
        maxWidth: "none",
        width: width || 1120,

        "> *": {
          ...fullWidth(),
          padding: 0,

          // "&:last-child": {
          //   paddingTop: 24,

          //   "> div": {
          //     height: "auto",

          //     "> div": {
          //       ...borderRadius(12),
          //     }
          //   }
          // }
        },

        h2: {
          ...flexbox("row", "center", "space-between"),

          button: {
            marginLeft: "auto"
          }
        },

        // Content
        "> div": {
          ...posRelative(),

          "> p": {
            ...borderRadius(6),
            ...paddingXY(3),
            ...posAbsolute(3, null, null, 3, 2),
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "#fff"
          },

          "> div": {
            ...fullWidth(),
            height: "auto",

            "> div": {
              ...borderRadius(12),
              height: "auto",

              "> div": {
                ...borderRadius(12),
              },

              "> img": {
                height: "auto",
                width: "100%!important",
              },
            }
          }
        }
      }
    },

    title: (hasTitle) => ({
      position: hasTitle ? "static" : "absolute",
      top: hasTitle ? null : 0,
      right: hasTitle ? null : 0,
    }),

    content: {
      ...flexbox("row", "flex-start", "space-between", 20, "wrap")
    },

    actions: {
      ...flexbox("row", "center", "space-between", 25)
    }
  })
};
