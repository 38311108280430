/** @jsxImportSource @emotion/react */

import { FormControl, MenuItem, Select } from "@mui/material";
import { borderRadius, flexbox, fontbox, fullWidth, paddingX, paddingY, theme } from "../../../emotion/utils";

const CustomSelect = ({
  label,
  value,
  variant = "outlined",
  options = [],
  onChange,
}) => {
  const existingVal = !!options.find(opt => opt.id === value)
    ? value
    : '';

  return (
    <FormControl css={css.formControl}>
      {label && <label css={css.label}>{label}</label>}

      <Select
        value={existingVal}
        variant={variant}
        onChange={onChange}
        css={css.select}
      >
        {options.map((opt) => (
          <MenuItem key={opt.id} value={opt.id}>
            {opt.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const css = {
  formControl: {
    ...flexbox("column", "flex-start", "flex-start", 2),
  },

  label: {
    ...fontbox(theme.fonts.inter, 14, 600, 20),
    color: theme.colors.black,

    "@media screen and (min-width: 992px)": {
      ...fontbox(theme.fonts.inter, 11, 600, 17),
    },
    "@media screen and (min-width: 1200px)": {
      ...fontbox(theme.fonts.inter, 14, 600, 20),
    },
  },

  select: {
    ...fullWidth(),

    "> div": {
      ...fontbox(theme.fonts.inter, 14, 600, 40),
      ...paddingX(16),
      ...paddingY(10),
      ...paddingY(0),
      zIndex: 1,

      "@media screen and (min-width: 992px)": {
        ...fontbox(theme.fonts.inter, 11, 600, 40),
        ...paddingX(8),
      },
      "@media screen and (min-width: 1200px)": {
        ...fontbox(theme.fonts.inter, 14, 600, 40),
        ...paddingX(16),
      },
    },
    "> svg": {
      zIndex: 1,
    },

    "> fieldset": {
      ...borderRadius(8),
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.borderPrimary}`,
      borderSizing: "border-box",
      boxShadow: `0 1px 2px 0 ${theme.colors.textPrimary}05`,
      height: 40,
    },
  },
};

export default CustomSelect;
