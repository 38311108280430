/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Button, ButtonGroup } from "@mui/material";

import noImgAvailable from "../../assets/images/noImgAvailable.png";

import ImgWithLoader from '../../components/img-with-loader';

import { css } from "./css";
import { CustomButton } from "../custom-button";
import { IconExpand } from "../icons/icon-expand";

export const ImageCard = ({
	camera,
	images,
  liveImage,
  selectBox,
	selectedTime,
	setSelectedCameraId,
  title,
}) => {
	const [isSelected, setIsSelected] = useState(false);
	const [zoom, setZoom] = useState(1);
	const [translateX, setTranslateX] = useState(0);
	const [origTranslateX, setOrigTranslateX] = useState(0);
	const [translateY, setTranslateY] = useState(0);
	const [origTranslateY, setOrigTranslateY] = useState(0);
	const [isDragging, setIsDragging] = useState(false);
	const [dragX, setDragX] = useState(0);
	const [dragY, setDragY] = useState(0);

	const imgRef = useRef();

	const onMouseDown = (e) => {
		setIsDragging(true);

    const { clientX, clientY } = (e.touches || [])[0] || e;

		setDragX(clientX);
		setDragY(clientY);
		setOrigTranslateX(translateX);
		setOrigTranslateY(translateY);
	};

	const increaseZoom = () => {
		setZoom(zoom * 1.5);
	};

	const decreaseZoom = () => {
		const newZoom = Math.max(zoom / 1.5, 1);

		const max = 100 * ((newZoom - 1) / (2 * newZoom));
		const min = -max;

		let xPerc = Math.min(translateX, max);
		xPerc = Math.max(xPerc, min);

		let yPerc = Math.min(translateY, max);
		yPerc = Math.max(yPerc, min);

		setTranslateX(xPerc);
		setTranslateY(yPerc);

		setZoom(newZoom);
	};

	const handleExpandClick = () => {
		const expandView = !isSelected
		setIsSelected(expandView);
    selectBox(camera)
    setZoom(1);
    setTranslateX(0);
    setTranslateY(0);
	};

  const handleButtonClick = () => {
    if (!disableBtn) {
      setSelectedCameraId(camera.id);
    }
  };

	const moveXY = (e) => {
		if (!isDragging) return;

		const max = 100 * ((zoom - 1) / (2 * zoom));
		const min = -max;

    const { clientX, clientY } = (e.touches || [])[0] || e;

		const xDisp = zoom * (clientX - dragX);
		const yDisp = zoom * (clientY - dragY);

		let xPerc = origTranslateX + ((xDisp / (e.target.clientWidth * zoom)) * (100 / zoom));
		let yPerc = origTranslateY + ((yDisp / (e.target.clientHeight * zoom)) * (100 / zoom));

		xPerc = Math.min(xPerc, max);
		xPerc = Math.max(xPerc, min);

		yPerc = Math.min(yPerc, max);
		yPerc = Math.max(yPerc, min);

		setTranslateX(xPerc);
		setTranslateY(yPerc);
	};

	let showImage = null;
	if (selectedTime) {
		const img = images
			?.sort((a, b) => a.created_at > b.created_at ? -1 : 1)
			.find((img) => {
				return moment(img.created_at).diff(selectedTime, "minutes") < 2;
			});

		showImage = img
			? {
				created_at: moment(img.created_at).format("MM/DD/YYYY hh:mm"),
				url: img?.url,
				text: 'Loading...'
			}
			: noImgAvailable;
	}

  const imgLabel = showImage?.created_at;

  const disableBtn = showImage === noImgAvailable;

  let btnLabel = 'View Live';

  if (liveImage?.loading) {
  	btnLabel = 'Checking Live...';
  } else if (liveImage?.error) {
  	btnLabel = 'No Live';
  }

  const getImg = (
    <div css={css().img(true ? "100%" : 300)}>
      <ImgWithLoader
        ref={imgRef}
        src={showImage?.url}
        alt=""
        draggable={false}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        onMouseUp={() => setIsDragging(false)}
        onTouchEnd={() => setIsDragging(false)}
        onMouseMove={moveXY}
        onTouchMove={moveXY}
        style={{
          transform: `scale(${zoom}) translate(${translateX}%, ${translateY}%)`,
          touchAction: 'none',
          position: 'relative'
        }}
      />
      
      {!disableBtn && (
        <ButtonGroup css={css().controls}>
          <Button onClick={increaseZoom}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M21.1117 11.5H13.5V3.98573C13.5 3.15744 12.8283 2.48573 12 2.48573C11.1717 2.48573 10.5 3.15744 10.5 3.98573V11.5H3.11169C2.28341 11.5 1.61169 12.1717 1.61169 13C1.61169 13.8283 2.28341 14.5 3.11169 14.5H10.5V21.9857C10.5 22.814 11.1717 23.4857 12 23.4857C12.8283 23.4857 13.5 22.814 13.5 21.9857V14.5H21.1117C21.94 14.5 22.6117 13.8283 22.6117 13C22.6117 12.1717 21.94 11.5 21.1117 11.5Z" fill="black"/>
            </svg>
          </Button>

          <Button onClick={decreaseZoom}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M21.1117 11.5H3.11169C2.28341 11.5 1.61169 12.1717 1.61169 13V13.7357C1.61169 14.564 2.28341 15.2357 3.11169 15.2357H21.1117C21.94 15.2357 22.6117 14.564 22.6117 13.7357V13C22.6117 12.1717 21.94 11.5 21.1117 11.5Z" fill="black"/>
            </svg>
          </Button>
        
          <Button onClick={handleExpandClick}>
            <IconExpand />
          </Button>
        </ButtonGroup>
      )}
    </div>
  );

	const getContent = (
		<div css={css().content}>
      <label>{title}</label>

      {imgLabel && (
        <div css={css().content.bottom}>
          <label>{imgLabel}</label>
        </div>
      )}

      <CustomButton
        label={btnLabel}
        disabled={liveImage?.error}
        onClick={handleButtonClick}
        variant="outlined"
        style={{
          cursor: liveImage?.error ? "normal" : "pointer"
        }}
      />
		</div>
	);

  return (
    <div css={css(isSelected ? "100%" : "48%")}>
      {getImg}
      {getContent}
    </div>
  );
};
