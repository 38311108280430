/** @jsxImportSource @emotion/react */

import {
	borderRadius,
	fontbox,
	fullWidth,
	getAlign,
	getButton,
	getButtonLabel,
	paddingX,
	paddingY,
	theme,
} from "../../emotion/utils";

export const CustomButton = ({
	disabled,
	label,
	labelStyle,
	variant = "contained",
	style,
	type = "button",
	onClick,
	color = "primary",
	align,
	isFullWidth = false,
}) => {
	const disabledStyle = disabled ? { opacity: 0.5 } : {};

	const alignStyle = getAlign(align);
	const buttonStyle = getButton(variant, color);
	const widthStyle = isFullWidth ? { ...fullWidth() } : {};
	const cssButton = [
		css.button(disabled),
		{
			...buttonStyle,
			...style,
			...disabledStyle,
			...alignStyle,
			...widthStyle,
		},
	];

	const buttonLabelStyle = getButtonLabel(variant, color);
	const cssButtonLabel = [
		css.button().label,
		{ ...buttonLabelStyle, ...labelStyle },
	];

	return (
		<button css={cssButton} onClick={onClick} type={type} disabled={disabled}>
			<span css={cssButtonLabel}>{label}</span>
		</button>
	);
};

const css = {
	button: (disabled) => ({
		...borderRadius(8),
		...paddingX(20),
		...paddingY(0),
		borderStyle: "solid",
		borderWidth: 1,
		boxSizing: "border-box",
		cursor: !disabled ? "pointer" : "default",
		flex: 1,
		height: 40,
		letterSpacing: 0,
		textTransform: "none",

		"@media (min-width: 576px)": {
			...paddingX(30),
		},
		"@media (min-width: 1200px)": {
			...paddingX(50),
		},

		label: {
			...fontbox(theme.fonts.inter, 16, 600, 38),
			display: "block",
		},
	}),
};
