import { useContext, useState } from 'react';
import { useQuery as reactQueryUseQuery } from '@tanstack/react-query';

import { LoginContext } from '../context/login-context';

export default function useProtectedQuery(props) {
  const { runProtected } = useContext(LoginContext);

  const [fetched, setFetched] = useState(false);

  const { onRefetch, onFetch } = props;

  const query = reactQueryUseQuery({
    ...props,
    queryFn: async (...args) => {
      const data = await runProtected(props.queryFn, ...args);

      if (onRefetch) onRefetch(data);
      if (!fetched && onFetch) {
        onFetch(data);
        setFetched(true);
      }

      return data;
    },
  });

  return query;
}
