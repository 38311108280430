/** @jsxImportSource @emotion/react */

import { useState, useRef, useEffect, useContext } from "react";
import { css as emoCss } from "@emotion/react";
import { ButtonGroup, Slider, Stack } from "@mui/material";
import moment from "moment";
import ReactGA from "react-ga4";

import {
	borderBottomLeftRadius,
	borderBottomRightRadius,
	borderRadius,
	borderTopLeftRadius,
	borderTopRightRadius,
	flexbox,
	fontbox,
	fullWidth,
	paddingX,
	paddingY,
	posAbsolute,
	posRelative,
	posStatic,
	theme,
} from "../../emotion/utils";
import { CustomButton } from "../custom-button";

import { ReactGAContext } from '../../context/react-ga';

export const Scrubber = ({
	leftLabel = null,
	rightLabel = null,
	min = 0,
	max = 100,
	markerValue = 0,
	markers,
	setSelectedTime,
	valueLabelDisplay = "auto",
	color = theme.colors.borderTertiary,
}) => {
	const { sendEvent } = useContext(ReactGAContext);

	const [value, setValue] = useState(markerValue);

	useEffect(() => {
		setValue(markerValue);
	}, [markerValue, markers]);

	const adjMarkers = markers?.map((m, index) => ({
		label: m?.show ? m?.label : "",
		value: m?.value,
		valueLabel: m?.label,
	}));

	const mark = adjMarkers?.find((m) => m?.value === value) || null;
	const markIndex = adjMarkers?.findIndex((m) => m?.value === value);

	const disableNext = !adjMarkers.length;

	const prevIndex = markIndex <= 0 ? mark?.length - 1 : markIndex - 1;
	const nextIndex = markIndex >= adjMarkers?.length - 1 ? 0 : markIndex + 1;

	const sliderRef = useRef();

	const handleScrubChange = (evt) => {
		const m = adjMarkers.find(
			(m) => m?.value === evt || m?.value === evt?.target?.value
		);

		if (m) {
			setSelectedTime(m.value);
			setValue(m.value);
		}
	};

	function handleScrubberPrev() {
		sendEvent({
      category: 'datetime',
      action: 'previous_image'
    });

		handleScrubChange(adjMarkers[prevIndex]?.value);
	}

	function handleScrubberNext() {
		sendEvent({
      category: 'datetime',
      action: 'next_image'
    });

		handleScrubChange(adjMarkers[nextIndex]?.value);
	}

	function handleSliderChange(e) {
    sendEvent({
      category: 'datetime',
      action: 'scrubber_timeline',
      value: e.target.value
    });

		handleScrubChange(e)
	}

	const left = leftLabel ? (
		<label style={{ paddingRight: "10px" }}>{leftLabel}</label>
	) : null;
	const right = rightLabel ? (
		<label style={{ paddingLeft: "10px" }}>{rightLabel}</label>
	) : null;

	const title = (
		<div css={css.title}>
			<label style={{ fontSize: "32px" }}>{moment(value).format("ddd M/DD/YY h:mm a")}</label>
		</div>
	);

	const getBtnGroup = (
		<ButtonGroup css={css.btnGroup}>
			<CustomButton
				label="Previous"
				variant="outlined"
				onClick={handleScrubberPrev}
				disabled={disableNext}
			/>
			<CustomButton
				label="Next"
				variant="outlined"
				onClick={handleScrubberNext}
				disabled={disableNext}
			/>
		</ButtonGroup>
	);

	return (
		<Stack
			spacing={2}
			direction="row"
			alignItems="center"
			color={color}
			sx={emoCss`${css.scrubber(color)}`}
		>
			{title}

			{left}

			<Slider
				aria-label="Volume"
				value={value}
				valueLabelDisplay={valueLabelDisplay}
				valueLabelFormat={mark?.valueLabel || 'None available'}
				min={min}
				max={max}
				marks={adjMarkers}
				ref={sliderRef}
				step={null}
				onChange={handleSliderChange}
			/>

			{right}

			{getBtnGroup}
		</Stack>
	);
};

const css = {
	scrubber: (color, variant) => ({
		...posRelative(),
		alignItems: "center",
    flexWrap: "wrap",
		margin: 0,

    "@media screen and (min-width: 768px)": {
      flexWrap: "nowrap",
    },

		"> label": {
			...fontbox(theme.fonts.inter, 12, 500, 20),
			...paddingX(`${0}!important`),
			color: theme.colors.textPrimary,

      "@media screen and (min-width: 576px)": {
			  ...fontbox(theme.fonts.inter, 16, 500, 24),
      },

			"&:first-of-type": {
				marginLeft: 0,
        order: 0,

        "@media screen and (min-width: 768px)": {
          order: "unset",
        },
			},
      "&:last-of-type": {
        marginRight: 0,
        marginLeft: "auto",
        order: 1,

        "@media screen and (min-width: 768px)": {
          marginLeft: 16,
          order: "unset",
        },
      },
		},

		".MuiSlider": {
			"&-root": {
        ...flexbox("row", "center", "space-between", 0),
				...paddingY(8),
				height: 8,
        marginRight: 16,
        order: 2,

        "@media screen and (min-width: 768px)": {
          order: "unset",
        },
			},

      "&-markLabel": {
        ...flexbox("column", "center", "center", 0),
        ...fontbox(theme.fonts.inter, 11, 500, 15),
        ...posStatic(),
        display: "inline-flex",
        marginTop: 55,
        maxWidth: 62,
        textWrap: "wrap",
        transform: "none",

        "@media screen and (min-width: 768px)": {
          ...fontbox(theme.fonts.inter, 12, 500, 15),
          maxWidth: "unset"
        },

        "&:first-of-type": {
          textAlign: "left",
        },

        "&:last-of-type": {
          marginLeft: "auto",
          textAlign: "right",
        },

        // ...posRelative(25, null, null, "50%"),
      },

			"&-rail": {
				backgroundColor: color,
				opacity: 1,
			},

			"&-track": {
				backgroundColor: `${theme.colors.textPrimary}15`,
				border: 0,
			},

			"&-thumb": {
				backgroundColor: theme.colors.white,
				border: `2px solid ${theme.colors.slider}`,
				boxShadow: `0 4px 8px -2px ${theme.colors.textPrimary}10, 0 2px 4px -2px ${theme.colors.textPrimary}06`,

				"&:hover, &.Mui-focusVisible": {
					boxShadow: `0px 0px 0px 8px ${theme.colors.textPrimary}15`,
				},
			},
		},
	}),

	title: {
		...borderRadius(4),
    ...fullWidth(),
		...paddingX(8),
		...paddingY(4),
		backgroundColor: `${theme.colors.valleyLight}25`,
		border: `1px solid ${theme.colors.valleyLight}65`,
    marginBottom: `15px!important`,
    textAlign: "center",
    
    "@media screen and (min-width: 768px)": {
		  ...paddingX(16),
      ...posAbsolute(-18, null, null, "50%", 0),
		  transform: ["translate(-50%, -50%)"],
      width: "auto",
    },

		"> label": {
      ...fontbox(theme.fonts.inter, `12px!important`, 900, 12),
      color: theme.colors.valleyLight,
      letterSpacing: 1,
      textTransform: "uppercase",

      "@media screen and (min-width: 768px)": {
        ...fontbox(theme.fonts.inter, `14px!important`, 900, 14),
        letterSpacing: 2,
      },
		},
	},

	btnGroup: {
    ...fullWidth(),
    marginTop: `40px!important`,
    marginLeft: `0px!important`,
    order: 3,

    "@media screen and (min-width: 768px)": {
      marginTop: `0px!important`,
      marginLeft: `16px!important`,
      order: "unset",
      width: "auto",
    },

		button: {
			...paddingX(10),
			borderColor: theme.colors.borderTertiary,
			height: 30,
			marginLeft: -1,
			transition: "all 0.3s ease",

			"&:not(disabled)&:hover": {
				borderColor: theme.colors.textPrimary,

				span: {
					color: theme.colors.textPrimary,
				},
			},

			"&:first-of-type": {
				...borderTopRightRadius(0),
				...borderBottomRightRadius(0),
			},
			"&:last-of-type": {
				...borderTopLeftRadius(0),
				...borderBottomLeftRadius(0),
			},

			span: {
				...fontbox(theme.fonts.inter, 12, 600, 30),
				color: theme.btnColors.primary,
				// backgroundColor: theme.colors.transparent,
				// height: 30,
				// width: 60,
				// padding: 0,
				// margin: 0,
				// borderRadius: 4,

				// "&:disabled": {
				//   color: theme.colors.textPrimary,
				//   borderColor: theme.colors.textPrimary,
			},
		},
	},

	button: (live) => ({
		...paddingX(20),
		backgroundColor: live
			? theme.btnColors.primary
			: theme.colors.transparent,
		borderColor: theme.btnColors.primary,
		height: 30,

		"> span": {
			...fontbox(theme.fonts.inter, 12, 600, 30),
			color: live ? theme.colors.white : theme.btnColors.primary,
		},
	}),
};
